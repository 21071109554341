#testimonials-section {
  background-image: url(../images/testimonial-bg.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  padding: 100px 0px !important;
  height: 500px;
  /* background-color: #f5f5f5 !important; */
}

.shadow-title {
  background-color: transparent !important;
  background-image: linear-gradient(
    180deg,
    #232c47 0%,
    #141d3817 91%
  ) !important;
  position: absolute !important;
  top: -6px;
  opacity: 0.6;
  text-transform: uppercase !important;
  user-select: none !important;
}
.shadow-title {
  font-size: 74px !important;
  font-weight: 900 !important;
  line-height: 1 !important;
  /* background-image: -webkit-linear-gradient(top, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%) !important; */
  /* background-image: linear-gradient(180deg, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%) !important; */
  background-image: linear-gradient(
    180deg,
    rgba(104, 77, 244, 0.6) 0%,
    rgba(255, 255, 255, 0) 76%
  ) !important;
  background-image: -webkit-linear-gradient(
    top,
    rgba(104, 77, 244, 0.6) 0%,
    rgba(255, 255, 255, 0) 76%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
  margin: -0.55em 0 -0.45em -0.25em !important;

  width: 100% !important;
  overflow: hidden !important;
}

.testimonial-card {
  position: relative;
  background-color: var(--white);
  box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  padding: 100px 40px 40px 40px;
  margin-top: 50px;
  margin-bottom: 30px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.testimonial-img {
  background-color: var(--title-color);
  width: 120px;
  text-align: center;
  border-radius: 10px;
  border: 10px solid var(--title-color);
  position: absolute;
  top: -50px;
  left: 40px;
}
.testimonial-img img {
  width: 100% !important;
  border-radius: 10px !important;
  height: 100px !important;
}

.testimonial-rating {
  color: var(--color1);
  font-size: 14px;
  position: absolute;
  top: 35px;
  right: 40px;
}

.testimonial-quote {
  width: 40px !important;
  position: absolute !important;
  bottom: -25px !important;
  right: 32px !important;
  background: var(--color1) !important;
  padding: 8px !important;
  border-radius: 50% !important;
  /* border: 2px solid var(--white) !important; */
}

/* .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
  } */

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(103, 9, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 16px rgba(103, 9, 255, 0);
  }
}
