.hero {
  /* background-image: url(../images/hero-bg.png) !important; */
  background-image: url(../images/hero-bg/hero-bg-121.png) !important;
  min-height: 600px !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  display: grid !important;
  place-items: center !important;
}
.arrow {
  position: absolute !important;
  right: -35px !important;
}

.sidetxt {
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.05);
  padding: 15px 25px !important;
  border-radius: 20px !important;
  background: white !important;
  position: absolute !important;
  text-align: center !important;
  min-width: 180px !important;
  display: flex !important;
  gap: 10 !important;
  justify-content: center !important;
  align-items: center !important;

  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.25) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

.txt1 {
  top: 35% !important;
  right: 75% !important;
}

.txt2 {
  top: 75% !important;
  right: 40% !important;
}

.sidetxt img {
  width: 30px !important;
}

.anime-txt {
  font-size: 16px !important;
  font-weight: bold !important;
  color: var(--color1) !important;
  margin-left: 10px !important;
}

/* arrow animation started */
.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* :horizontal moving animation */

.hori-move {
  -webkit-animation: Hmover 1s infinite alternate;
  animation: Hmover 1s infinite alternate;
}
.hori-move {
  -webkit-animation: Hmover 1s infinite alternate;
  animation: Hmover 1s infinite alternate;
}
@-webkit-keyframes Hmover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
@keyframes Hmover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

.MainHead {
  font-weight: bold !important;
  font-size: 4rem !important;
  /* font-size: 3.5rem !important; */
  color: var(--title-color) !important;
  text-shadow: -1px -1px 1px #fff, 2px 2px 1px #fff;
  /* color: white !important;
    text-shadow: -1px -1px 1px #c6c6c6, 2px 2px 1px #565656; */
}

.SubHead {
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  color: var(--black2) !important;
}

.cta-button-1 {
  background-color: var(--title-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 1.5px !important;
  color: var(--white) !important;
  padding: 8px 20px !important;
}

.cta-button-1:hover {
  background-color: #fff !important;
  color: var(--title-color) !important;
  transition: all 0.5s ease-in-out !important;
}

.cta-button-2 {
  background-color: var(--color1) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 1.5px !important;
  color: var(--white) !important;
  padding: 8px 20px !important;
  border: 2px solid var(--color1) !important;
}

.cta-button-2:hover {
  background-color: #fff !important;
  color: var(--color1) !important;
  transition: all 0.5s ease-in-out !important;
}

/* .cta-button-2 {
  background-color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 1.5px !important;
  color: var(--title-color) !important;
  padding: 8px 20px !important;
}

.cta-button-2:hover {
  background-color: var(--title-color) !important;
  color: var(--white) !important;
  transition: all 0.5s ease-in-out !important;
} */


@media (max-width: 767px) { 
  .hero{
    padding-top: 70px !important;
    padding-bottom: 50px !important;
  }
  .arrow {
    position: absolute !important;
    right: 30px !important;
  }
  .txt1 {
    top: 20% !important;
    right: 35% !important;
  }
  .txt2 {
    right: -2% !important;
    top: 70% !important;
  }
  .MainHead{
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .SubHead{
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .cta-button-1 { 
    font-size: 12px !important; 
    letter-spacing: 1px !important; 
  }
  .process-number{
    left: auto !important;
  }
}