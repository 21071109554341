/* Header CSS Started */
#appbar {
  /* background-color: transparent !important; */
  box-shadow: none !important;
  transition: all 0.5s ease-in-out !important;

  background: rgba(255, 255, 255, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

#appbar.scrolled {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  transition: all 0.5s ease-in-out !important;

  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(4px) !important;
}

.logo {
  width: 250px !important;
  transition: all 0.8s ease-in !important;
}

.section {
  /* padding: 40px 0px !important; */
  padding: 60px 0px !important;
}

.w100 {
  width: 100% !important;
}

.abt-over {
  background-color: var(--color1) !important;
  border-radius: 50% !important;
  border: 10px solid var(--white) !important;
  height: 180px !important;
  width: 180px !important;
  position: absolute !important;
  text-align: center !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  right: 0px !important;
  bottom: 40px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.abt-over h4 {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: var(--white) !important;
  text-align: center !important;
}
.abt-over h6 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--white) !important;
  text-align: center !important;
}

.sm-title {
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase !important;
}
.sm-title img {
  width: 20px !important;
  margin-right: 6px !important;
}

.MuiList-root {
  padding: 0px !important;
}
.MuiListItem-root {
  padding: 0px !important;
}
#about-us .MuiListItemAvatar-root {
  min-width: 65px !important;
}
#about-us .MuiAvatar-root {
  background-color: transparent !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 0px !important;
}
#about-us .MuiAvatar-root img {
  width: 100% !important;
}

#why-choose-us .MuiListItemAvatar-root {
  min-width: 50px !important;
}
#why-choose-us .MuiAvatar-root {
  background-color: transparent !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  padding: 5px !important;
  border: 2px dotted var(--success) !important;
}
#why-choose-us .MuiAvatar-root img {
  width: 100% !important;
}

/* Latest Projects section CSS started */
#latest-projects {
  background-image: url(assets/images/projects/tech-bg.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: #f5f5f5 !important;
}
/* 
.project-card{
  background-color: #fff !important;
  padding: 20px !important;
  position: relative !important;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
} */

/* .card-overlay {
  background: linear-gradient(0deg, #a704ff 0, rgba(0, 91, 226, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.4;
  border-radius: 12px !important;
  visibility: hidden;
  transition: all .8s ease-in !important;
}

.project-card:hover .card-overlay{
  visibility: visible;
  opacity: 0.7;
  transition: all .8s ease-in !important;
} */

.visit-now {
  text-transform: uppercase !important;
  font-size: 11px !important;
  /* background-color: #E2E8FA !important; */
  background-color: #fff !important;
  box-shadow: none !important;
  color: var(--title-color) !important;
  padding: 6px 16px !important;
}

/* FAQ section css started */
#faq-accordian .MuiAccordion-root::before {
  display: none !important;
}

#faq-accordian .MuiAccordion-root {
  margin: 8px 0 !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  border-radius: 4px !important;
}

#faq-accordian .MuiAccordion-root.Mui-expanded {
  margin: 8px 0 !important;
}

/* Footer Section CSS STarted */
.before-footer-strip {
  background-color: var(--title-color) !important;
  padding: 30px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;

  border-bottom: 8px solid var(--color1) !important;
  border-top: 8px solid var(--color1) !important;
}

.conact-card-list .MuiAvatar-root {
  height: 60px;
  width: 60px;
  border-radius: 6px !important;
  background: #fff !important;
  color: var(--color1) !important;
}

.conact-card-list .MuiListItemAvatar-root {
  min-width: 70px !important;
}

#footer {
  background-color: var(--title-color) !important;
  background-image: url(assets/images/footer-bg.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.skew {
  height: 136px !important;
  width: 12px !important;
  background: var(--color1) !important;
  position: absolute;
  right: 25px;
  top: -2px;
  transform: skew(-35deg) !important;
}

/* footer gird started */
.footer-grid-title {
}

.footer-grid-title::before {
  content: "";
  height: 2px;
  width: 100px;
  background-color: var(--color1);
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer-grid-title::after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: var(--white);
  position: absolute;
  bottom: -4px;
  left: 96px;
  border-radius: 99px;
  border: 2px solid var(--color1);
}

.footer-grid-title {
  /* max-width: 270px; */
  color: var(--white);
  text-transform: uppercase;
  padding: 0 0 10px 0;
  position: relative;
  font-size: 20px !important;
  font-weight: 500 !important;
  /* font-family: var(--title-font); */
  /* line-height: 1em; */
  /* margin: -0.12em 0 28px 0; */
}

.footer-nav {
  font-size: 15px !important;
  text-transform: none !important;
  letter-spacing: 0.6px;
  min-width: auto !important;
  padding: 4px 0px !important;
  text-align: left !important;
  justify-content: flex-start !important;
  color: var(--white) !important;
  opacity: 0.4 !important;
}
.footer-nav:hover {
  background-color: transparent !important;
  color: var(--color1) !important;
}
.active_menu .footer-nav {
  color: var(--white) !important;
  opacity: 1 !important;
}

#footer .MuiAvatar-root {
  height: 70px;
  width: 70px;
  border-radius: 6px !important;
  background: #fff !important;
  color: var(--color1) !important;
}

#footer .MuiListItemAvatar-root {
  min-width: 85px !important;
}

.footer-copyright-strip {
  background-color: #2d2b71 !important;
  padding: 10px !important;
}

#contact-us {
  /* background-color: #f5f5f5 !important; */
  background-image: url(assets/images/appointment-bg.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

#contact-us .MuiList-root {
  background: var(--white) !important;
  padding: 6px 6px !important;
  border-radius: 4px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 2px !important;
}

#contact-us .conact-card-list .MuiAvatar-root {
  background-color: var(--color1) !important;
  color: var(--white) !important;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6) !important;
}

#working-process {
  background-color: aliceblue !important;
}

#working-process {
  background-image: url(assets/images/process/process-bg.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: #868686 !important;
}

.work-box {
  background-color: #fff !important;
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06) !important;
  padding: 30px !important;
  padding-top: 40px !important;
  border-radius: 12px !important;
  position: relative !important;
}
.work-box h6 {
  min-height: 130px !important;
}

.process-number {
  width: 70px !important;
  height: 70px !important;
  display: grid !important;
  place-items: center !important;
  position: absolute !important;
  /* bottom: -25px !important;
  right: 32px !important; */
  background: var(--color1) !important;
  padding: 8px !important;
  border-radius: 50% !important;
  user-select: none !important;
  left: -30px !important;
  top: -30px !important;
}
.process-number span {
  font-size: 28px !important;
  font-weight: 700 !important;
  color: var(--white) !important;
  font-family: var(--title-font) !important;
}

.process-line {
  width: 100% !important;
  position: absolute;
  top: 120px;
}

.process-img {
  height: 80px !important;
  width: auto !important;
}

.footer-logo {
  text-align: end !important;
  margin-right: 120px;
}

.footer-logo .logo {
  width: 75px !important;
  height: auto !important;
}

.li {
  color: #0077b5 !important;
}
.insta {
  color: #ee2a7b !important;
}
.wa {
  color: #075e54 !important;
}

/* Header Menus css started */
.header-menu {
  font-size: 13px !important;
  letter-spacing: 0.5px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: var(--title-color) !important;
}
.header-menu:hover {
  color: var(--color1) !important;
  background-color: transparent !important;
}

.MuiPopover-paper.MuiMenu-paper {
  background-color: #fff !important;
}
.MuiMenu-list {
  padding: 4px 12px !important;
  /* background-color: transparent !important; */
}

/* dropdown menus css started */
/* .header-dropdown-menu{
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: var(--title-color) !important;
}
.header-dropdown-menu:hover{
  color: var(--white) !important;
  background-color: transparent !important;
  background-color: var(--color1) !important;
  border-radius: 4px !important;
} */

.active_menu .header-menu {
  font-weight: 500 !important;
  color: var(--color1) !important;
}

.active_menu .header-dropdown-menu {
  box-shadow: inset 0px 0px 35px var(--color1) !important;
}
.active_menu .header-dropdown-menu::before,
.active_menu .header-dropdown-menu::after {
  transform: scale(0);
}

#linear-progress {
  /* top: 62px !important;
  position: sticky !important;
  z-index: +91; */
  top: 0px !important;
  position: sticky !important;
  z-index: +9999999;
}

#linear-progress .MuiLinearProgress-bar {
  background-color: var(--color1) !important;
}

#linear-progress .MuiLinearProgress-root {
  background-color: transparent !important;
  /* height: 6px !important; */
  height: 3px !important;
}

/* mobile drawer css started */
.MuiDrawer-paper {
  background-color: var(--white) !important;
}

.mobilemenuview .MuiAccordion-root {
  background-color: inherit !important;
  border-bottom: 1px solid var(--title-color) !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.mobilemenuview .Mui-expanded {
  margin: 0px !important;
}

.mobilemenuview .MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.mobilemenuview .MuiAccordionSummary-root {
  min-height: 48px !important;
  max-height: 48px !important;
}
.mobilemenuview .header-menu {
  text-align: left !important;
  justify-content: flex-start !important;
}

.desktopviewmenu {
  display: block !important;
}

@media (max-width: 767px) {
  .desktopviewmenu {
    display: none !important;
  }
  .tab-hide {
    display: none !important;
  }
  .skew {
    display: none !important;
  }
  #testimonials-section {
    height: auto !important;
    min-height: 500px !important;
  }
  .mob_c{
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
  }
  /* .hero{
    padding-top: 70px !important;
    padding-bottom: 50px !important;
  } */
  /* .arrow {
    position: absolute !important;
    right: 30px !important;
  } */
}

@media (min-width: 767px) {
  .mobileviewmenu {
    display: none !important;
  }
}

/* Loading scrren css started */

/* .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1) !important;
} */



/* 
.loader-button {
  background: green;
  border: 2px solid grey;
  color: white;
  display: inline-block;
  font-family: "Trebuchet MS", sans-serif;
  overflow: hidden;
  padding: 15px 45px 15px 40px;
  position: relative;
  text-decoration: none;
  transition: 0.4s ease;
  z-index: 1;
  &:before,
  &:after {
    background: grey;
    transition: 0.3s ease;
    content: "";
    position: absolute;
    z-index: -1;
  }
}


.left {
  &:before {
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
  }
  &:hover {
    &:before {
      right: 0;
    }
  }
} */


.loader-button-left{
  background: transparent;
  border: 2px solid transparent;
  border: none !important;
  color: white;
  display: inline-block;
  overflow: hidden;
  padding: 15px 45px 15px 40px;
  width: 200px;
  position: relative;
  text-decoration: none;
  transition: 0.4s ease;
  z-index: 1;
}

.loader-button-left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: var(--color1);
  z-index: -1;
  transition: 0.3s ease; 
  animation: slide-in 0.5s ease-in-out;  
}

@keyframes slide-in {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}




.loader-button-right{
  background: transparent;
  border: 2px solid transparent;
  border: none !important;
  color: white;
  display: inline-block;
  overflow: hidden;
  padding: 15px 45px 15px 40px;
  width: 200px;
  position: relative;
  text-decoration: none;
  transition: 0.4s ease;
  z-index: 1;
}

.loader-button-right:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: var(--color1);
  z-index: -1;
  transition: 0.3s ease; 
  animation: slide-in 0.5s ease-in-out;  
}

@keyframes slide-in {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}




.loader-backdrop.MuiBackdrop-root {
  background-color: black !important;
  background-image: url(assets/images/loader-bg.gif);
  background-position: center !important;
  background-size: cover !important;
  padding: 20px 40px !important;
}
.skip-loading-button{
  position: absolute !important;
  top: 60px !important;
  right: 60px !important;

  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: none !important;
  color: #9c9c9c !important;
}
.skip-loading-button:hover{
  color: var(--white) !important;
}


.highlight {
  background-color: yellow;
}


/* .carousel-bg{
  background-color: var(--color1) !important;
  min-height: 50vh !important;
  position: relative !important;
}

.carousel-bg .carousel{
  position: initial !important;
}

.carousel-caption{
position: relative !important;
}
  */

.MuiInputLabel-root.Mui-focused{
  color: var(--color1) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--color1) !important;
}
.MuiInputBase-input.MuiOutlinedInput-input{
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.MuiFormLabel-root.MuiInputLabel-root{
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.8 !important;
  /* transform: translate(14px, -5px) scale(0.75); */ 
}
.MuiFormHelperText-root{
  font-size: 10px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}

 