.project-card {
  position: relative;
  /* margin: 0 auto; */
  /* padding: 30px !important; */
  width: 100% !important;
  list-style: none;
  text-align: center;
  border-radius: 4px !important;
}

/* Common style */
.project-card figure {
  position: relative;
  /* float: left; */
  overflow: hidden;
  /* margin: 10px 1%; */
  width: 100%;
  margin: 0 !important;
  height: auto !important;
  border-radius: 4px !important;
  padding: 0px !important;
  /* background: #00bbff; */
  background: rgb(73, 52, 186) !important;
  background: linear-gradient(
    0deg,
    rgba(73, 52, 186, 1) 0%,
    rgba(119, 94, 245, 1) 31%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  cursor: pointer;
  /* border: 2px solid var(--white) !important; */
}

.project-card figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.project-card figure figcaption {
  padding: 30px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.project-card figure figcaption::before,
.project-card figure figcaption::after {
  pointer-events: none;
}

.project-card figure figcaption,
.project-card figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figcaption .btn-link{
  position: relative !important;
}

figure.effect-chico img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

figure.effect-chico:hover img {
  opacity: 0.4;
  filter: blur(2px);
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico figcaption {
  padding: 3em;
  display: grid !important;
  place-items: center !important;
}

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  /* border-radius: 12px ; */
  content: "";
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico .desc {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

/* figure.effect-chico h2 {
    padding: 20% 0 20px 0;
  } */

figure.effect-chico .desc {
  margin: 0 auto;
  /* max-width: 200px; */
  width: auto;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover .desc {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.project-card:hover .chips {
  z-index: 0 !important;
  transition: all 0.2s ease-in-out !important;
}

.chips {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  z-index: 1 !important;
  transition: all 0.2s ease-in-out !important;
}
.chips .MuiChip-root {
  height: auto !important;
  padding: 4px 4px !important;
  border-radius: 4px !important;
}
.chip1 {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 12px !important;

  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4.5px) !important;
  -webkit-backdrop-filter: blur(4.5px) !important;
  color: var(--title-color) !important;
  /* border-radius: 10px !important; */
  border: 1px solid var(--yellow) !important;
}
.chip2 {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 12px !important;

  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4.5px) !important;
  -webkit-backdrop-filter: blur(4.5px) !important;
  color: var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
}
