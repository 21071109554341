@import url(Styles.css);
@import url(assets/css/hero.css);
@import url(assets/css/services.css);
@import url(assets/css/projects.css);
@import url(assets/css/testimonials.css);
@import url(assets/css/animated-buttons.css);

html {
  height: 100%;
  box-sizing: border-box;
  line-height: 0;
  scroll-behavior: smooth !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: white !important;
  position: relative;
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

a:hover {
  color: var(--color1) !important;
}

:root {
  --color1: #684df4;
  --title-color: #141d38;
  --text-color: #737887;
  --text-light-color: #72849b;
  --black: #000000;
  --black2: #080e1c;
  --white: #ffffff;
  --gray: #bdbdbd;
  --yellow: #ffb539;
  --success: #28a745;
  --error: #dc3545;
  --title-font: "Barlow", sans-serif;
  --body-font: "Roboto", sans-serif;
}

.font1 {
  font-family: var(--title-font) !important;
}
.font2 {
  font-family: var(--body-font) !important;
}

.color1 {
  color: var(--color1) !important;
}
.title-color {
  color: var(--title-color) !important;
}
.text-color {
  color: var(--text-color) !important;
}
.text-light-color {
  color: var(--text-light-color) !important;
}
.black {
  color: var(--black) !important;
}
.black2 {
  color: var(--black2) !important;
}
.white {
  color: var(--white) !important;
}
.gray {
  color: var(--gray) !important;
}
.yellow {
  color: var(--yellow) !important;
}
.success {
  color: var(--success) !important;
}
.error {
  color: var(--error) !important;
}

.al_center {
  text-align: center !important;
}
.al_left {
  text-align: left !important;
}
.al_right {
  text-align: right !important;
}
.al_jst {
  text-align: justify !important;
}
.fx_c {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.fx_fs {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.fx_fe {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.fx_sb {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.no-select {
  user-select: none !important;
}
.fs13 {
  font-size: 13px !important;
}

.fw4 {
  font-weight: 400 !important;
}
.fw5 {
  font-weight: 500 !important;
}
.fw6 {
  font-weight: 600 !important;
}
.fw7 {
  font-weight: 700 !important;
}

h2 {
  font-size: 40px !important;
}
h3 {
  font-size: 32px !important;
}
h4 {
  font-size: 28px !important;
}
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 16px !important;
}

.one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 45px !important;
}

.three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 68px !important;
}

.stroke {
  stroke: var(--title-color) !important;
  stroke-width: 8px !important;
}

.wbg {
  background-color: var(--white) !important;
}


/* New Chrome Dev Feature */
/* ***** Works in Chrome , Edge  */
/* Doesnt works in Firefox */

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.8;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.appear-on-scroll {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 25% cover 50%;
}

/* New Feature View Transition -- Navigation */
@view-transition {
  navigation: auto;
}



/* background video */
.video-background {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensures the video stays behind the content */
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
}

.overlay{
  background: #ffffff70 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}