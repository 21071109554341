.header-dropdown-menu {
    width: 100% !important;
    position: relative !important; 
    /* height: 3.5em !important; */
    border: 3px ridge var(--color1) !important;
    outline: none !important;
    background-color: transparent !important;
    color: var(--title-color) !important;
    transition: 1s !important;
    border-radius: 0px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    margin: 6px 0px !important;
  }
  
  .header-dropdown-menu::after {
    content: "";
    position: absolute;
    top: -4px;
    left: 3%;
    width: 95%;
    height: 10px;
    background-color: #fff;
    transition: 0.5s;
    transform-origin: center;
  }
  
  .header-dropdown-menu::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 10px;
    background-color: #fff;
    transition: 0.5s;
  }
  
  .header-dropdown-menu:hover::before, .header-dropdown-menu:hover::after {
    transform: scale(0)
  }
  
  .header-dropdown-menu:hover {
    box-shadow: inset 0px 0px 35px var(--color1) !important;
  }