.dropdown {
  display: inline-block;
  color: #343f52 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  z-index: 1;
}

/* .dropdown-content a {
  color: #343f52 !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
} */

/* .dropdown-content a:hover {
  background-color: #f1f1f1;
} */

.dropdown:hover .dropdown-content {
  display: block;
  transition: all 0.5s ease-in !important;
}
