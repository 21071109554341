
#our-services {
    background-image: url(../images/services/service_bg.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: #f5f5f5 !important;
  }
 

  #faq {
    background-image: url(../images/services/service_bg.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: #f5f5f5 !important;
  }
 

  
.services-read-more{
    text-transform: uppercase !important;
    font-size: 13px !important;
    background-color: #E2E8FA !important;
    box-shadow: none !important;
    color: var(--title-color) !important;
    padding: 8px 20px !important;
  }

  

.service-card .shape-icon {
    /* margin-bottom: 30px; */
  }
  
  .icon-mui {
    font-size: 30px !important;
    color: var(--color1) !important;
  }
  .shape-icon {
    position: relative;
    z-index: 2;
    width: 90px;
    height: 85px;
    line-height: 85px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .shape-icon::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #e2e8fa;
    -webkit-clip-path: path(
      "M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z"
    );
    clip-path: path(
      "M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z"
    );
    z-index: -1;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
  }
  
  .shape-icon .dots::before {
    top: 0;
    right: 9px;
  }
  .shape-icon .dots::before,
  .shape-icon .dots::after {
    content: "";
    position: absolute;
    background-color: var(--color1);
    height: 24px;
    width: 23px;
    border-radius: 50%;
  }
  
  .shape-icon .dots::after {
    height: 12px;
    width: 11px;
    bottom: 0;
    left: 27px;
  }
  
  .service-card_number {
    position: absolute;
    top: 20px !important;
    right: 40px !important;
    font-size: 100px !important;
    line-height: 1 !important;
    font-weight: bold !important;
    color: #e2e8fa !important;
    opacity: 0.3 !important;
  }
  
  .go-corner {
    /* display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    bottom: 0;
    right: 0;
    background-color: var(--color1);
    border-radius: 32px 0 0 0; */
  
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 85px;
    overflow: hidden;
    bottom: 0px;
    right: 0px;
    background-image: url(../images/services/service-shape.png) !important;
    background-size: cover !important;
    height: 81px !important;
    background-repeat: no-repeat !important;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  /* .service-card {
    display: block;
    position: relative; 
    background-color: #fff;
    border-radius: 12px;
    padding: 25px 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important; 
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  
    &:before {
      content: "";
      position: absolute;
      z-index: -1; 
      bottom: 0px !important;
      right: 0px !important;
      background: var(--color1);
      background-image: url(../images/services/service_card_bg.png) !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      height: 32px;
      width: 32px; 
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: all 0.25s ease-out;
    }
  
    &:hover:before { 
      height: 100% !important;
      width: 100% !important;
    }
  } */
  

  .service-card {
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    padding: 25px 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    user-select: none !important;
  }
  
  .service-card:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0px !important;
    right: 0px !important;
    background: var(--color1);
    background-image: url(../images/services/service_card_bg.png) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 32px;
    width: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: all 0.25s ease-out;
  }
  
  .service-card:hover:before {
    height: 100% !important;
    width: 100% !important;
  }
  
  .service-card:hover {
    h4 {
      transition: all 0.3s ease-out;
      color: var(--white) !important;
    }
    h6 {
      transition: all 0.3s ease-out;
      color: var(--white) !important;
    }
    .services-read-more {
      background-color: var(--white) !important;
    }
  }
  