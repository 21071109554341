
:root {
  --default-left-diff: 10px;
  --default-height: 300px;
  --default-min: 50px;
}


.card-stack {
  /* width: 500px;
  height: 250px;
  margin: 0 auto;
  position: relative;
  top: 200px !important; */
}

.cards-fullpage{
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.card-list {
  /* width: 300px;
  position: absolute; */
  list-style: none;
  padding: 0; 
  width: 100% !important;
  position: relative !important;
  display: grid;
  align-items: center !important;
}

.card {
  transition: all 100ms ease-in-out;
  border-radius: 12px;
  position: absolute;
  height: 300px;
  /* box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5); */
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 20px;
  text-align: center;
  background-size: cover;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}

.card:nth-child(1) {
  /* left: calc(var(--default-diff) * 2); */
  left: 0px !important;
  /* height: calc(var(--default-height) - var(--default-min) * 5); */
  height: 220px !important;
}

.card:nth-child(2) {
  left: calc(var(--default-left-diff)) !important;
  /* left: 10px !important; */
  /* height: calc(var(--default-height) - var(--default-min) * 4); */
  height: 240px !important;
}

.card:nth-child(3) {
  /* left: 20px !important; */
  left: calc(var(--default-left-diff) * 2) !important;
  /* left: calc(var(--default-diff) * 4); */
  /* height: calc(var(--default-height) - var(--default-min) * 3); */
  height: 260px !important;
}

.card:nth-child(4) {
  /* left: calc(var(--default-diff) * 5); */
  /* left: 30px !important; */
  left: calc(var(--default-left-diff) * 3) !important;
  height: 280px !important;
}

.card:nth-child(5) {
  /* left: calc(var(--default-diff) * 6); */
  /* left: 40px !important; */
  left: calc(var(--default-left-diff) * 4) !important;
  height: 300px !important;
} 

.card-top {
  transform: translateX(60px) scale(1.1);
  transition: transform 0.5s ease-in-out;
  z-index: 1;
  width: 100% !important;
  /* left: calc(var(--default-diff) * 2) !important; */
  /* top: 125px !important; */
  }
  .card.card-top{
  height: 300px !important;
  left: 20px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important; 
  background-color: white !important;
  border-left: 4px solid red !important;
}


.active-button{
  background-color: red !important;
}